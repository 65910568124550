import React       from 'react';
// import { Link }    from "react-router-dom";

class Footer extends React.Component {

  state = {};

  render(){
    return (<div></div>) // Removed for now.
  };
}

export default Footer;